import { QuestionCircleOutlined, SaveOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  Checkbox,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip
} from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { ColumnsType } from 'antd/lib/table'
import { AxiosResponse } from 'axios'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Link } from 'react-router-dom'

import {
  CloudconfigConfiguration,
  CloudconfigChangeFileReq,
  CloudconfigCreateConfigurationChangeResp,
  CloudconfigParameterSource,
  CloudconfigPreviewConfigurationChangeResp,
  CloudconfigNamedParameter,
  CloudconfigUnifiedParameter,
  CloudconfigConfigurationFiles,
  CloudconfigParameterApplyPolicy
} from 'apiClient/services/devops/interface'
import ModalForm from 'components/ModalForm'

import EditParamForm, {
  EditParamType,
  IsBool,
  IsFloat
} from '../Forms/EditParamForm'
import PreviewForm, {
  PreviewFormType,
  convertToString
} from '../Forms/PreviewForm'

import ExportInfo from './ExportInfo'

export interface PreviewParam {
  files?: CloudconfigChangeFileReq[]
  [key: string]: any
}
export interface GetResp {
  [key: string]: any
}
export type GetCloudConfigController = (
  params: unknown,
  options?: unknown
) => Promise<AxiosResponse<GetResp>>
export type PreviewCloudConfigController = (
  params: PreviewParam,
  options?: unknown
) => Promise<AxiosResponse<CloudconfigPreviewConfigurationChangeResp>>
export type CreateCloudConfigController = (
  params: PreviewParam,
  options?: unknown
) => Promise<AxiosResponse<CloudconfigCreateConfigurationChangeResp>>

const CurrentConfig = ({
  getAPI,
  getParam,
  cacheAPI,
  cacheParam,
  previewAPI,
  previewParam,
  createAPI,
  createParam,
  type,
  upgradeForm,
  upgradeTo
}: {
  getAPI: GetCloudConfigController
  getParam: unknown
  cacheAPI: GetCloudConfigController
  cacheParam: unknown
  previewAPI: PreviewCloudConfigController
  previewParam: PreviewParam
  createAPI: CreateCloudConfigController
  createParam: PreviewParam
  type: string
  upgradeForm?: Dispatch<SetStateAction<boolean>>
  upgradeTo?: string
}) => {
  const [configuration, setConfiguration] = useState<CloudconfigConfiguration>()
  const [files, setFiles] = useState<CloudconfigConfigurationFiles>()
  const [filename, setFilename] = useState<string>('tidb')
  const [modalEdit, setModalEdit] = useState(false)
  const [modalCommit, setModalCommit] = useState(false)
  const [modalExport, setModalExport] = useState(false)
  const [modalEditInitial, setModalEditInitial] = useState<{
    configType?: string
    filename?: string
    name?: { value: string; label: string }
    old?: unknown
  }>()
  const [reload, setReload] = useState(false)
  const [modified, setModified] = useState(false)

  const [preview, setPreview] =
    useState<CloudconfigPreviewConfigurationChangeResp>()
  const [searchInput, setSearchInput] = useState<string>('')
  const [changeable, setChangeable] = useState<{
    changeable: boolean
    reason: string
  }>()

  useEffect(() => {
    fetch()
  }, [reload])

  async function fetch() {
    try {
      const s = (await getAPI(getParam)).data
      const c = (await cacheAPI(cacheParam)).data
      setConfiguration(s.configuration)
      setFiles(c.configuration.files)
      setChangeable(s.changeable)
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    }
  }

  const fillPostParam = () => {
    let sources: string[] = ['custom']
    let res: CloudconfigChangeFileReq[] = []
    Object.keys(files!).map((k) => {
      const fn = files![k].filename
      let text
      if (configuration!.files![k] === undefined) {
        text = undefined
      } else {
        text = configuration!.files![k].text
      }
      // const text = configuration!.files![k].text
      let custom: CloudconfigNamedParameter[] = []
      // let modified: CloudconfigChangedParameter[] = []
      Object.keys(files![fn!].all_parameters!).map((kp) => {
        const to = getActualValue(files!, fn!, kp)
        if (sources.includes(files![fn!].all_parameters![kp].source!)) {
          custom.push({ name: kp, value: to })
        }
      })
      res.push({
        custom_parameters: custom,
        config_type: fn,
        filename: files![k].filename,
        // modified_parameters: modified,
        previous_text: text
      })
    })
    return res
  }

  async function commit() {
    let req = previewParam
    req.files = fillPostParam()
    if (upgradeTo !== undefined) {
      req.version = { to_version: upgradeTo }
    }
    try {
      // FIX ME
      const data = (await previewAPI(req)).data
      setPreview(data)
      // if (upgradeForm !== undefined) {
      //   upgradeForm(false)
      // }
      setModalCommit(true)
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    }
  }

  const columns: ColumnsType<UnifyParam> = useMemo(
    () => [
      {
        title: 'Name',
        render: (_: any, record) => {
          return record.param.name
        }
      },
      {
        title: 'Filename',
        render: (_: any, record) => {
          return record.filename
        }
      },
      // FIX ME UPGRADE
      ...(type === 'cluster' || type === 'upgrade'
        ? [
            {
              title: (
                <>
                  Source &nbsp;
                  <Tooltip
                    title={
                      <div>
                        custom: the value has been manually set by the user.
                        <br />
                        template: the value provided by the template.
                        <br />
                        engine: the parameter is unset and its value provided by
                        the database engine.
                      </div>
                    }
                    overlayInnerStyle={{ width: '400px' }}
                  >
                    <QuestionCircleOutlined
                      style={{ fontSize: '16px', color: '#08c' }}
                    />
                  </Tooltip>
                </>
              ),
              render: (_: any, record: UnifyParam) => {
                return String(record.param.source) ===
                  CloudconfigParameterSource.template ? (
                  <Link
                    to={`/template/${
                      configuration?.files![record.filename].template_id
                    }`}
                    target="_blank"
                  >
                    {String(record.param.source)}
                  </Link>
                ) : (
                  <>{String(record.param.source)}</>
                )
              }
            }
          ]
        : []),
      {
        title: 'Value',
        render: (_: any, record) => {
          let origin = getActualValue(
            configuration!.files!,
            record.filename,
            record.param.name!
          )
          origin =
            origin !== (undefined || null)
              ? origin
              : getDefaultValue(files!, record.filename, record.param.name!)
          return convertToString(
            getActualValue(files!, record.filename, record.param.name!)
          ) === convertToString(origin) ? (
            convertToString(origin)
          ) : (
            <>
              <p style={{ color: 'red' }}>
                {convertToString(
                  getActualValue(files!, record.filename, record.param.name!)
                )}
              </p>
            </>
          )
        }
      },
      {
        title: 'Apply Policy',
        render: (_: any, record) => {
          return String(record.param.attribute?.apply_policy)
        }
      },
      {
        title: 'Action',
        key: 'action',
        render: (_: any, record) => (
          <div>
            <a
              onClick={() => {
                setModalEditInitial({
                  configType: record.filename,
                  filename: record.filename,
                  name: {
                    value: record.param.name!,
                    label: record.param.name!
                  },
                  old:
                    getActualValue(
                      configuration?.files!,
                      record.filename,
                      record.param.name!
                    ) === (undefined || null)
                      ? convertToString(
                          getDefaultValue(
                            files!,
                            record.filename,
                            record.param.name!
                          )
                        )
                      : convertToString(
                          getActualValue(
                            configuration?.files!,
                            record.filename,
                            record.param.name!
                          )
                        )
                })
                setModalEdit(true)
              }}
            >
              Edit
            </a>
          </div>
        )
      }
    ],
    [files, filename]
  )

  const renderEditParamFields = useCallback(
    (form: FormInstance) => {
      form.setFieldsValue({
        configType: modalEditInitial?.configType,
        filename: modalEditInitial?.filename,
        name: modalEditInitial?.name,
        old: modalEditInitial?.old
      })
      return (
        <EditParamForm
          {...modalEditInitial}
          form={form}
          version={configuration?.version}
          files={configuration?.files}
        />
      )
    },
    [modalEdit, filename, configuration, modalEditInitial]
  )

  const handleEdit = useCallback(
    (payload: EditParamType) => {
      payload.newVal = parseFormValue(payload.type, String(payload.newVal!))
      payload.defaultVal = parseFormValue(
        payload.type,
        String(payload.defaultVal)
      )
      handleParamChange(payload)
      setModalEdit(false)
      setFilename(payload.filename)
    },
    [files, configuration]
  )

  const handleParamChange = (payload: EditParamType) => {
    let tmp = files || {}
    if (!tmp[payload.filename]) {
      tmp![payload.filename!] = {
        all_parameters: {},
        config_type: payload.configType,
        filename: payload.filename,
        text: ''
      }
      tmp![payload.filename!].all_parameters![payload.name.value] = {
        custom: { value: payload.newVal },
        source: 'custom',
        name: payload.name.value,
        attribute: {
          default_value: payload.defaultVal,
          type: payload.type,
          apply_policy: payload.applyPolicy
        }
      }
    } else if (!tmp[payload.filename].all_parameters) {
      tmp[payload.filename].all_parameters = {}
      tmp![payload.filename!].all_parameters![payload.name.value] = {
        custom: { value: payload.newVal },
        source: 'custom',
        name: payload.name.value,
        attribute: {
          default_value: payload.defaultVal,
          type: payload.type,
          apply_policy: payload.applyPolicy
        }
      }
    } else if (!tmp[payload.filename].all_parameters![payload.name.value]) {
      tmp[payload.filename].all_parameters![payload.name.value] = {
        custom: { value: payload.newVal },
        source: 'custom',
        name: payload.name.value,
        attribute: {
          default_value: payload.defaultVal,
          type: payload.type,
          apply_policy: payload.applyPolicy
        }
      }
    } else {
      tmp[payload.filename].all_parameters![payload.name.value].custom = {
        value: payload.newVal
      }
      tmp[payload.filename].all_parameters![payload.name.value].source =
        'custom'
    }
    setFiles(tmp)
  }

  const handleApply = useCallback(
    async (payload: PreviewFormType) => {
      let req = createParam
      req.files = fillPostParam()
      req.apply_policy =
        payload.apply_policy || CloudconfigParameterApplyPolicy.RollingRestart
      req.reason = payload.reason
      if (upgradeTo !== undefined) {
        req.version = { to_version: upgradeTo }
      }
      try {
        let resp = (await createAPI(req)).data.change_id
        Modal.success({
          title: 'Info',
          content: (
            <div>
              <p>
                This config change has been created.
                <br></br>
                However, you should go to to the <b>Detail</b> Page to check the
                Approval information and Apply it. Click <b>OK</b> will redirect
                you to that page.
              </p>
            </div>
          ),
          onOk() {
            window.open(`/confighistories/${resp}`, '_blank')
          }
        })
        setModalCommit(false)
        setReload((pre) => !pre)
      } catch (error) {
        Modal.error({
          title: error.response.data.errcode,
          content: error.response.data.errmsg
        })
      } finally {
        if (upgradeForm) {
          upgradeForm(false)
        }
      }
    },
    [files]
  )

  const handleCancelEdit = useCallback(() => {
    setModalEdit(false)
  }, [])

  const renderPreviewParamFields = useCallback(
    (form: FormInstance) => {
      return <PreviewForm preview={preview} type={type} />
    },
    [modalCommit]
  )

  const handleCancelPreview = useCallback(() => {
    setModalCommit(false)
  }, [])

  return (
    <div>
      {changeable && changeable.changeable === false ? (
        <Alert
          style={{ marginBottom: '5px' }}
          message={
            'You cannot change the config currently, because ' +
            changeable.reason
          }
          type="warning"
          showIcon
        />
      ) : (
        <></>
      )}
      {type === 'upgrade' ? (
        <Alert
          style={{ marginBottom: '5px' }}
          message={
            'Preview the cluster configuration after upgrade. You can also edit them and the modification will be applied together with the upgrade.'
          }
          type="info"
          showIcon
        />
      ) : (
        <></>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div style={{ display: 'flex', gap: '10px' }}>
          <Select
            options={Object.keys(files || {}).map((v) => {
              return { value: v, label: v }
            })}
            value={filename}
            style={{ width: 'auto', alignSelf: 'flex-start' }}
            onChange={(e: string) => {
              setFilename(e)
            }}
            defaultValue={'tidb'}
          ></Select>
          <Input
            style={{ width: '300px' }}
            placeholder="config key name to search"
            onChange={(e) => {
              setSearchInput(e.target.value)
            }}
          ></Input>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setModified(true)
              } else {
                setModified(false)
              }
            }}
          >
            only show modified params
          </Checkbox>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            type="primary"
            onClick={() => {
              setModalEditInitial({})
              setModalEdit(true)
            }}
          >
            More Configs
          </Button>
          {type === 'cluster' ? (
            <Button
              icon={<SaveOutlined />}
              onClick={() => {
                setModalExport(true)
              }}
            >
              Export
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <br></br>
      <Table
        dataSource={mapToList(files || {})
          .filter((v) => {
            return v.filename === filename
          })
          .filter((v) => {
            return v.param.name?.includes(searchInput)
          })
          .filter((v) => {
            if (modified) {
              let origin = getActualValue(
                configuration!.files!,
                v.filename,
                v.param.name!
              )
              origin =
                origin !== (undefined || null)
                  ? origin
                  : getDefaultValue(files!, v.filename, v.param.name!)
              return (
                convertToString(
                  getActualValue(files!, v.filename, v.param.name!)
                ) !== convertToString(origin)
              )
            } else {
              return true
            }
          })
          .sort((a, b) => {
            if (a.param.source === b.param.source) {
              if (
                getActualValue(files!, a.filename, a.param.name!) ===
                  getActualValue(
                    configuration?.files!,
                    a.filename,
                    a.param.name!
                  ) &&
                getActualValue(files!, b.filename, b.param.name!) ===
                  getActualValue(
                    configuration?.files!,
                    b.filename,
                    b.param.name!
                  )
              ) {
                return 0
              } else if (
                getActualValue(files!, a.filename, a.param.name!) ===
                getActualValue(configuration?.files!, a.filename, a.param.name!)
              ) {
                return 1
              } else {
                return -1
              }
            } else if (a.param.source === 'custom') {
              return -1
            } else {
              return 1
            }
          })}
        columns={columns}
        pagination={{ pageSize: 10, position: ['bottomLeft'] }}
      />
      <div
        className="flex-container"
        style={{ justifyContent: 'flex-end', display: 'flex' }}
      >
        <div>
          <Button
            type="primary"
            onClick={() => {
              commit()
            }}
            disabled={files === undefined}
          >
            Preview Changes
          </Button>
        </div>
      </div>
      <ModalForm<EditParamType>
        visible={!!modalEdit}
        name="editParam"
        title={'Edit Parameter'}
        getFields={renderEditParamFields}
        onSubmit={handleEdit}
        onCancel={handleCancelEdit}
        width={600}
        destoryOnClose={true}
        okText="OK"
      />
      <ModalForm<PreviewFormType>
        visible={!!modalCommit}
        name="preview"
        title={'Preview of Change'}
        getFields={renderPreviewParamFields}
        onSubmit={handleApply}
        onCancel={handleCancelPreview}
        width={1000}
        okText="Commit"
      />
      <Modal
        visible={!!modalExport}
        title={'Configuration'}
        onCancel={() => setModalExport(false)}
        footer={null}
      >
        <ExportInfo configuration={configuration}></ExportInfo>
      </Modal>
    </div>
  )
}

export type UnifyParam = {
  key: string
  filename: string
  param: CloudconfigUnifiedParameter
}

const mapToList = (m: CloudconfigConfigurationFiles) => {
  var l: UnifyParam[] = []
  Object.keys(m).forEach((key) => {
    const fileAvailableParameterGroup = m[key]
    const fileValue = fileAvailableParameterGroup.all_parameters || {}
    Object.keys(fileValue).forEach((key2) => {
      const p = fileValue[key2]
      l.push({ filename: key, param: p, key: key + '_' + p.name })
    })
  })
  return l
}

export const getActualValue = (
  files: CloudconfigConfigurationFiles,
  filename: string,
  paramName: string
) => {
  if (!files) {
    return null
  }
  if (files[filename] === (null || undefined)) {
    return null
  } else if (files[filename].all_parameters === (null || undefined)) {
    return null
  } else if (
    files[filename].all_parameters![paramName] === (null || undefined)
  ) {
    return null
  } else {
    let p = files[filename].all_parameters![paramName]
    if (p.source === 'custom') {
      return p.custom?.value
    } else if (p.source === 'template') {
      return p.template?.value
    } else {
      return p.attribute?.default_value
    }
  }
}

const getDefaultValue = (
  files: CloudconfigConfigurationFiles,
  filename: string,
  paramName: string
) => {
  if (files[filename] === (null || undefined)) {
    return null
  } else if (files[filename].all_parameters === (null || undefined)) {
    return null
  } else if (
    files[filename].all_parameters![paramName] === (null || undefined)
  ) {
    return null
  } else {
    let p = files[filename].all_parameters![paramName]
    return p.attribute?.default_value
  }
}

export const parseFormValue = (type: string | undefined, val: string) => {
  if (type === undefined || type === null || type === 'unknown') {
    if (IsFloat(val)) {
      return parseFloat(val)
    } else if (IsBool(val)) {
      if (val === 'true') {
        return true
      } else {
        return false
      }
    } else {
      return val
    }
  }
  if (type === 'bool') {
    if (val === 'true') {
      return true
    } else {
      return false
    }
  } else if (type === 'float') {
    return parseFloat(val)
  } else if (type === 'int') {
    return parseInt(val)
  } else if (type === 'json') {
    return JSON.parse(val)
  } else {
    return val
  }
}

export default CurrentConfig
